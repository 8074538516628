module.exports = {
  siteTitle: 'Nathan Green Ph.D', // <title>
  manifestName: 'NathanGreen.com',
  manifestShortName: 'NathanGreen', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#3d8a0e',
  manifestThemeColor: '#3d8a0e',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  pathPrefix: `/nathan-green/`, // This path is subpath of your hosting https://domain/portfolio
  firstName: 'Nathan',
  lastName: 'Green',
  title:'Ph.D',

  // social
  socialLinks: [
    {
      icon: 'fa-google',
      name: 'Google Scholar',
      url: 'https://scholar.google.com/citations?user=76LU144AAAAJ&hl=en',
    },
    {
      icon: 'fa-github',
      name: 'Github',
      url: 'https://github.com/ndgreen',
    },
    {
      icon: 'fa-linkedin-in',
      name: 'Linkedin',
      url: 'https://www.linkedin.com/in/nathandavidgreen/',
    },
    {
      icon: 'fa-circle-thin',
      name: 'CV',
      url: '/cv.pdf',
      text:'CV',

    },

  ],
  email: 'nathan@nathangreen.com',
  email2: 'nathan.green@marymount.edu',
};
